@import "global.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  width: 100%;
}

.content {
  margin: 24px 0;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.leftBlock {
  width: 100%;
  display: flex;
  
  @include only-mobile {
    margin-bottom: 20px;
  }

  @include desktop {
    width: 50%;
  }
}

.lContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin: auto;

  @include tablet {
    width: 90%;
  }
  @include desktop {
    width: 70%;
  }

  .title {
    @include heading-h4;
    color: $color-base-900;

    @include tablet {
      @include heading-h2;
    }
  }
  
  .text {
    @include heading-h6;
    color: $color-base-700;
  }
  
  .form {
    display: flex;
    gap: 20px;
  }
}

.button {
  border-radius: 24px;
  width: 50px;
  font-size: 0;
  background: var(--primary-500, #184BFF);

  @include tablet {
    width: 20%;
    font-size: 14px;
    border-radius: 12px;
  }
  @include desktop {
    width: 35%;
  }

  .buttonInner {
    padding-left: 12px;
  }
}

.inputWrapper {
  width: 100%;
  .input {
    border-radius: 12px;
  }
}

.rightBlock {
  margin: auto;
  
  @include tablet-only {
    display: none;
  }

  .mainImage {
    max-width: 100%;
    height: auto;
  }
}
